@mixin flexbox($theme: row) {

    display: flex;
    flex-direction: $theme;
    justify-content: center;
    align-items: center;
}

.buffer {
            margin: 15px;
            width: 0;
            height: 150px;
            border-left: 1px solid rgba(255,255,255,.25);
        }

.projects {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .project {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;
        margin: 10px 30px;
        .project-content {
            max-width: 500px;
        }
        
        
        
        .project-content {
            @include flexbox($theme: column);
            align-items: flex-start;
            margin: 20px;
            h3 {
                align-self: flex-start;
                margin-block: 5px;
            }
            a { 
                border-bottom: 1px solid rgba(255,255,255);
                padding: 0;
            }
            p {
                margin-block: 10px;
                text-align: justify;
            }
        }
        img {
            width: auto;
            height: 400px;
            margin: 20px;
            box-shadow: 10px 10px 50px black;
            -moz-box-shadow: 10px 10px 50px black;
      -webkit-box-shadow: 10px 10px 50px black;
            filter: opacity(95%);
            border-radius: 5px;
        }

        img:hover {
            cursor: pointer;
        }
    }
}

@media (max-width: 700px) { 
        #project-image {
        height: 300px;
    }
}

@media (max-width: 1250px) {
    .projects {

    .project {
        flex-direction: column;

    .buffer {
        width: 200px;
        height: 0px;
        border-top: 1px solid rgba(255,255,255,.25);
    }
}
    .reverse {
        flex-direction: column-reverse;
    }
}
}

@media (max-width: 430px) {
    #project-image {
        height: 220px;
    }
}

