@mixin flexbox($theme: row) {

    display: flex;
    flex-direction: $theme;
    justify-content: center;
    align-items: center;
}

html {
  scroll-behavior: smooth;
}

* {
    font-family: 'Rubik', sans-serif;
    margin: 0;
    padding: 0;
//   border: 1px solid black;
}

.body {
    overflow-x: hidden;
}

:root {
    --toastify-color-success: #17C28C;
}

::-webkit-scrollbar {
    background-color: #2F2C2D; 
  height: 6px;
  width: 6px;
  border-radius: 100vw;
}

::-webkit-scrollbar-track {
  background: grey;
  border-radius: 100vw;
}

::-webkit-scrollbar-thumb {
    background: black;
  border-radius: 100vw;
}



.hidden {
    opacity: 0;
}

.show {
    transition: all 1s;
    opacity: 1;
}

.title {
    padding: 10px;
    padding-inline: 20px;
    border-bottom: 1px solid rgba(255,255,255,.25);
    margin: 20px;
}

.page{
    @include flexbox($theme: column);
    position: relative;
    align-items: center;
    background-color: #2F2C2D; 
    overflow: hidden;
    overflow: clip;
    color: #EBEAEF;
   a, button {
    text-decoration: none;
    color: #EBEAEF;
       }
}

i{ display: flex;
        justify-content: center;
        padding: 10px;
        margin: 0 auto;
        width: 60px;
        font-size: 40px;
        text-shadow: 2px 9px 4px black;
}

li {
    margin: 5px;
    margin-left: 15px;
}

.anchor, .options-anchor {
     display: block;
     height: 10vh; /*same height as header*/
     margin-top: -10vh; /*same height as header*/
     visibility: hidden;
     width: 100%;
 }

 .options-anchor {
     height: 20vh;
     margin-top: -20vh;
 }

.component {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    img {
        width: 200px;
    }
}



