
@mixin flexbox($theme: row) {

    display: flex;
    flex-direction: $theme;
    justify-content: center;
    align-items: center;
}

.section {
    @include flexbox($theme: column);
    align-items: flex-start;
    justify-content: flex-start;
    -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
  padding-inline: 10px;
  ::-webkit-scrollbar {
    background-color: #2F2C2D; 
  }
      ::-webkit-scrollbar-track {
          margin-inline: 50px;
    background-color: #2F2C2D; 
}
    ::-webkit-scrollbar-thumb {
        background-color: rgba(255,255,255,.25);
    }
    .mobile-chevrons {
        padding-top: 20px;
        margin: 0 auto;
        .chevrons {
    font-size: 30px;
    margin: 20px 10px;
        }
    }
.section-carousel {
    @include flexbox;
    width: 100%;
.section-container {
    width: 100%;
    padding-top: 20px;
        max-width: 700px;
        overflow-x: auto;
        scroll-behavior: smooth;
    .section-scroller {
        @include flexbox;
        width: 1300%;
        max-width: 9100px;
        .image-container {
            @include flexbox($theme: column);
            width: 100%;
            height: 100%;
            img {
                width: 90%;
                height: 80%;
                margin: 0;
                border-radius: 7px;
                margin-bottom: 5px;
                box-shadow: 5px 5px 10px black;
            }
            span {
                font-style: italic;
            }
            p {
                height: 10%;
                font-size: 13px;
                padding: 0;
                margin: 0;
                padding-left: 20px;
                width: 90%;
                margin: 0;
            }
    }
}
}
}
}


