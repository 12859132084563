@mixin flexbox($theme: column) {

    display: flex;
    flex-direction: $theme;
    justify-content: center;
    align-items: center;
}

.experience, .education {
    width: 80vw;
    max-width: 900px;
    .experience-section {
        @include flexbox;
        align-items: flex-start;
        width: 100%;
        margin: 10px;
    }
    .titles {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-block: 15px;
        h3 {
            margin-block: 3px;
        }
        .position {
            font-size: 20px;
        }
            .italic {
                font-style: italic;
            }
        .second {
            @include flexbox($theme: column);
            justify-content: flex-end;
            align-items: flex-end;
            text-align: right;
        }
    }
    ul, p {
        margin-bottom: 10px;
    }
}

